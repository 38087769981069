<template>
  <div>
    <vue-headful
      title="Proposal: create or load template | Octoa"
    />
    <v-inner-header
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :redirect="`/projects/${projectId}`"
    />

    <v-video-modal
      name="choose"
      title="New Proposal or load from a template"
      description="A video to help you get started."
      step="proposal-choose"
      thumbnail="proposal-choose.png"
      embed="bnBSmANceDE"
      :show.sync="isShowVideoModal"
      @close="closeVideoModal"
    />

    <div class="max-w-lg mx-auto pt-24 px-4">
      <div class="bg-white px-5 py-5 rounded shadow">
        <div class="flex items-center justify-between">
          <div>
            <h1 class="text-xl">
              Create new proposal
            </h1>
          </div>
          <div>
            <v-tutorial-button @click="showVideoModal" />
          </div>
        </div>
        <router-link
          class="green-btn mt-6 inline-block"
          :to="`/projects/${projectId}/proposal/create`"
        >
          Create blank proposal
        </router-link>

        <div
          v-if="proposals && proposals.length > 0"
          class="mt-8"
        >
          <h2 class="mb-8 text-lg">
            Or create based on proposal template
          </h2>
          <div
            v-for="proposal in proposals"
            :key="proposal.id"
            class="flex items-center mb-3 cursor-pointer border border-solid border-grey-d1 rounded px-4 py-4"
            @click="loadTemplate(proposal)"
          >
            <img
              src="@/assets/img/icons/proposal.svg"
              width="25"
              class="align-middle"
            >
            <span class="ml-3 mt-1 inline-block">Proposal template: {{ proposal.name }}</span>
          </div>
        </div>

        <div
          v-else
          class="mt-8"
        >
          <h1 class="mb-2 text-lg">
            Or create a new proposal template
          </h1>
          <p>We recommend to create a proposal template first if you have frequent similar proposals.</p>

          <router-link
            class="green-btn mt-6 inline-block"
            :to="`/templates/proposals/create?project=${projectId}`"
          >
            Create template proposal
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import auth from '@/auth'
import { logException } from '@/helpers'
import { createCancelSource } from '@/http'

let cancelSource

export default {
  data(){
    return {
      isSaving: false,
      isSaved: false,
      loading: false,
      projectId: this.$route.params.id,
      proposals: {},
      project: {},
      user: auth.user(),
      isShowVideoModal: false,
      currency: {},
    }
  },
  async mounted(){
    await this.checkIntroVideo()
    cancelSource = createCancelSource()
    try {
      const { data } = await this.$api.get('templateProposal').list(this.user.company.id, {
        cancelToken: cancelSource.token
      })

        this.proposals = data.proposals
        this.loading = false
    } catch(e){
      logException(e)
    }

    try {
      const { data } = await this.$api.get('projects').load(this.projectId, {
        cancelToken: cancelSource.token
      })

      if(!data.project.currency_id){
        this.currency = this.user.companySettings.currency
      } else {
        this.currency = data.project.currency
      }

      this.project = data.project

    } catch(e){
      logException(e)
    }

  },
  methods: {
    async loadTemplate(item, type){
      try {
        const data = await this.$store.dispatch('template/loadTemplate', item.id)
        if(data == 'success'){
          if(item.currency_id != this.project.currency_id){
            const can = await this.$alert.confirm({
              title: 'The proposal template currency is different from the project.',
              text: `Do you want to continue to use the project currency: ${this.project.currency.name}?`,
            })
            if (can) {
              this.$router.push(`/projects/${this.projectId}/proposal/create?load=1`)
            }
          } else {
            this.$router.push(`/projects/${this.projectId}/proposal/create?load=1`)
          }
        }
      } catch(e) {
        this.$toasted.global.api_error(e)
      }
    },
    async checkIntroVideo(){
      let showModal = false
      // let showModal = true
      // this.user.me.companySettings.watchIntroVideos.forEach(video => {
      //   if(video == 'proposal-choose'){
      //     showModal = false
      //   }
      // })

      if(showModal){
        this.isShowVideoModal = true
      }
    },
    showVideoModal(){
      this.isShowVideoModal = true
    },
    closeVideoModal(){
      this.isShowVideoModal = false
    },
  }
}
</script>
